
//Ajouter et supprimer des classes
const oldAdd = (element, className) => {
    let classes = element.className.split(" ");
    if (classes.indexOf(className) < 0) {
        classes.Push(className)
    };
    element.className = classes.join(" ")
};
const oldRemove = (element,className) => {
    let classes = element.className.split(" ");
    //old const idx = classes.indexOf(className);
    className = classes.indexOf(className);
    if (className > -1) {
        classes.splice(className, 1)
    };
    element.className = classes.join(" ")
};
const addClass = (element,className) => {
    if (element.classList) {
        element.classList.add(className)
    } else {
        oldAdd(element, className)
    };
};
const removeClass = (element, className) => {
    if (element.classList) {
        element.classList.remove(className)
    } else {
        oldRemove(element, className)
    };
};

//Lire et écrir les cookies
function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + 24 * days * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null
};
function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

//fonctions du pop-up cookiesConsent
function consentGranted() {
    if (typeof gtag === "function") {
        gtag("consent", "update", {
            ad_storage: "granted",
            analytics_storage: "granted",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "denied"
        });
    };
    var cookiesConsent = document.getElementById("cookiesConsent");
    addClass(cookiesConsent, "d-none");
    //Si cookie non-present --> créer cookie
    var
        x = getCookie("cookiesConsent"),
        y = getCookie("sessionConsent");
    if (!x) {
        setCookie('cookiesConsent','true',730);//cookie
    };
    if (y) {
        eraseCookie('sessionConsent');
    };
};
function consentDenied() {
    if (typeof gtag === "function") {
        gtag("consent", "update", {
            ad_storage: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "denied"
        });
    };

    var cookiesConsent = document.getElementById("cookiesConsent");
    addClass(cookiesConsent, "d-none");
    var
    x = getCookie("cookiesConsent"),
    y = getCookie("sessionConsent");
    //Si cookie present --> suprimer cookie
    if (x) {
        eraseCookie('cookiesConsent');
    };
    //Si session non-present --> créer session
    if (!y) {
        setCookie('sessionConsent','true');//session
    };
};
function consentChoose() {
    var
        consentDetail = document.getElementById("consentDetail"),
        consentDetailTwo = document.getElementById("consentDetailTwo"),
        consentHome = document.getElementById("consentHome"),
        consentHomeTwo = document.getElementById("consentHomeTwo");
    removeClass(consentDetail, "d-none");
    removeClass(consentDetailTwo, "d-none");
    addClass(consentDetailTwo, "d-flex");
    removeClass(consentHomeTwo, "d-flex");
    addClass(consentHomeTwo, "d-none");
    addClass(consentHome, "d-none");
};
function consentBack() {
    var
        consentDetail = document.getElementById('consentDetail'),
        consentDetailTwo = document.getElementById('consentDetailTwo'),
        consentHome = document.getElementById('consentHome'),
        consentHomeTwo = document.getElementById('consentHomeTwo');
    removeClass(consentHome, 'd-none');
    removeClass(consentHomeTwo, 'd-none');
    addClass(consentHomeTwo, 'd-flex');
    addClass(consentDetail, 'd-none');
    removeClass(consentDetailTwo, 'd-flex');
    addClass(consentDetailTwo, 'd-none');
};
function consentApply() {
    var
        cookiesConsent = document.getElementById("cookiesConsent"),
        consentAnalytics = document.getElementById("consentAnalytics");
    // var x = getCookie('cookiesConsent');
    if (consentAnalytics.checked) {
        consentGranted()
    } else {
        consentDenied()
    };
    addClass(cookiesConsent, "d-none")
};
// function consentApply() {
//     const
//         cookiesConsent = document.getElementById('cookiesConsent'),
//         consentAd = document.getElementById("consentAd"),
//         consentAnalytics = document.getElementById("consentAnalytics");
//     if (consentAd.checked && consentAnalytics.checked) {
//         consentGranted()
//         choiceInPersistant()
//     } if (!consentAd.checked && !consentAnalytics.checked) {
//         consentDenied()
//         choiceInSession()
//     } if (consentAd.checked && !consentAnalytics.checked) {
//         gtag('consent', 'update', {
//             ad_storage: "granted",
//             analytics_storage: "denied",
//             functionality_storage: "denied",
//             personalization_storage: "denied",
//             security_storage: "denied"
//         });
//         choiceInSession()
//     } if (consentAnalytics.checked && !consentAd.checked) {
//         gtag('consent', 'update', {
//             ad_storage: "denied",
//             analytics_storage: "granted",
//             functionality_storage: "denied",
//             personalization_storage: "denied",
//             security_storage: "denied"
//         });
//         choiceInPersistant()
//     }
//     addClass(cookiesConsent, 'd-none')
// };

function consentManagement() {
    var cookiesConsent = document.getElementById("cookiesConsent");
    removeClass(cookiesConsent, "d-none");
};

// Si cookiesConsent et cookiesManagement existes
if (typeof document.getElementById("cookiesConsent") !== undefined && typeof document.getElementById("cookiesManagement") !== undefined) {
    //Vérification de l'état du consentement
    document.addEventListener("DOMContentLoaded", function() {
        //Si cookie ou session de consentement present --> pas de pop-up #cookiesConsent
        var x = getCookie("cookiesConsent"),
            y = getCookie("sessionConsent");
        if (x || y) {
            const cookiesConsent = document.getElementById("cookiesConsent");
            addClass(cookiesConsent, "d-none")
            if (x) {
                // consentement ok
                if (typeof gtag === "function") {
                    gtag("consent", "update", {
                        ad_storage: "granted",
                        analytics_storage: "granted",
                        functionality_storage: "denied",
                        personalization_storage: "denied",
                        security_storage: "denied"
                    });
                };
            };
        } else {
            //sinon --> envoyer pop-up #cookiesConsent
        }
    });

    //Actions bouttons pop-up cookiesConsent
    document.addEventListener("DOMContentLoaded", function() {
        document.getElementById("consentGranted").addEventListener("click", consentGranted),
        document.getElementById("consentGrantedTwo").addEventListener("click", consentGranted),
        document.getElementById("consentDenied").addEventListener("click", consentDenied),
        document.getElementById("consentChoose").addEventListener("click", consentChoose),
        document.getElementById("consentBack").addEventListener("click", consentBack),
        document.getElementById("consentApply").addEventListener("click", consentApply),
        document.getElementById("cookiesManagement").addEventListener("click", consentManagement)
    });
};
